import { get as _get, isString } from 'lodash';
import { Field, EnrichedDataRecord } from '../../../types';
import { FieldCheckboxValue } from '../../../types/fieldValues';
import { FilterCondition } from '../../../types/filters';

export const getCheckboxFilteringFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>
) => {
  return (record: EnrichedDataRecord) => {
    const value = _get(record, [
      'enrichedFields',
      condition.fieldId,
    ]) as FieldCheckboxValue;
    if (value === undefined) {
      return true;
    }
    const compareValue = value.compareValue;
    let compareTo = condition.compareTo;
    if (compareValue === undefined) {
      return true;
    }
    if (isString(compareTo)) {
      if (compareTo === 'true') {
        compareTo = true;
      }
      if (compareTo === 'false') {
        compareTo = false;
      }
    }
    return compareValue === compareTo;
  };
};
