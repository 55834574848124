import { toNumber, get as _get } from 'lodash';

import { Field, EnrichedDataRecord } from '../../../types';
import { FieldNumberValue } from '../../../types/fieldValues';
import {
  FilterCondition,
  NUMBER_FILTER_EXPRESSION_OPTIONS,
} from '../../../types/filters';

const numberExpressionOptions = {
  '=': (numberIn: number, compareTo: number) => {
    return numberIn === compareTo;
  },
  // if it is undefined then it doesn't equal the compare to...
  '!=': (numberIn: number | undefined, compareTo: number) =>
    numberIn === undefined || numberIn !== compareTo,
  '<': (numberIn: number | undefined, compareTo: number) =>
    numberIn !== undefined && numberIn < compareTo,
  '<=': (numberIn: number | undefined, compareTo: number) =>
    numberIn !== undefined && numberIn <= compareTo,
  '>': (numberIn: number | undefined, compareTo: number) =>
    numberIn !== undefined && numberIn > compareTo,
  '>=': (numberIn: number | undefined, compareTo: number) =>
    numberIn !== undefined && numberIn >= compareTo,
  'is empty': (numberIn: number | undefined) => numberIn === undefined,
  'is not empty': (numberIn: number | undefined) => numberIn !== undefined,
};

export const getNumberFilterFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
) => {
  return (record: EnrichedDataRecord) => {
    const value = _get(record, [
      'enrichedFields',
      condition.fieldId,
    ]) as FieldNumberValue;
    if (value === undefined) {
      if (condition.expression === 'is empty') {
        return true;
      }
      if (condition.expression === 'is not empty') {
        return false;
      }
      return false;
    }
    const compareToNum = toNumber(condition.compareTo as any);
    const compareValue = value.compareValue;

    const comparisonFunc = numberExpressionOptions[
      condition.expression as NUMBER_FILTER_EXPRESSION_OPTIONS
    ] as (numberIn: number | undefined, compareTo: any) => boolean;
    return comparisonFunc(compareValue, compareToNum);
  };
};
