import { FieldType } from '../../types';

export const DEFAULT_FILTER_BOOL_FOR_UNDEFINED_FIELD_VALUE = true;
export type ExpressionWithIsOption =
  | 'is'
  | 'is not'
  | 'is before'
  | 'is after'
  | 'is on or before'
  | 'is on or after';
export const dateExpressionWithIsOptions: ExpressionWithIsOption[] = [
  'is',
  'is not',
  'is before',
  'is after',
  'is on or before',
  'is on or after',
];
export type ExpressionWithIsWithinOption = 'is within';

export const dateExpressionWithWithinOptions: ExpressionWithIsWithinOption[] = [
  'is within',
];
export type ExpressionIsOptionWithNumericCompareTo =
  | 'number of days ago'
  | 'number of days from now';
export const dateIsOptionsWithNumericCompareTo: ExpressionIsOptionWithNumericCompareTo[] =
  ['number of days ago', 'number of days from now'];
export type ExpressionIsOptionWithDateCompareTo = 'exact date...';
export const dateIsOptionsWithDateCompareTo: ExpressionIsOptionWithDateCompareTo[] =
  ['exact date...'];
export type ExpressionIsOptionWithoutCompareTo =
  | 'today'
  | 'tomorrow'
  | 'one week ago'
  | 'one week from now'
  | 'one month ago'
  | 'one month from now';

export const dateIsOptionsWithoutCompareTo: ExpressionIsOptionWithoutCompareTo[] =
  [
    'today',
    'tomorrow',
    'one week ago',
    'one week from now',
    'one month ago',
    'one month from now',
  ];
export type ExpressionIsWithinOptionWithNumericCompareTo =
  | 'the next number of days'
  | 'the past number of days';
export const dateIsWithinOptionsWithCompareTo: ExpressionIsWithinOptionWithNumericCompareTo[] =
  ['the next number of days', 'the past number of days'];
export type ExpressionIsWithinOptionWithoutCompareTo =
  | 'the past week'
  | 'the past month'
  | 'the past year'
  | 'the next week'
  | 'the next month'
  | 'the next year'
  | 'the next number of days'
  | 'the past number of days';
export const dateIsWithinOptionsWithoutCompareTo: ExpressionIsWithinOptionWithoutCompareTo[] =
  [
    'the past week',
    'the past month',
    'the past year',
    'the next week',
    'the next month',
    'the next year',
    'the next number of days',
    'the past number of days',
  ];
const dateFilterExpressionOptions = [
  ...dateExpressionWithIsOptions.map((expression) => {
    return {
      expression,
      options: [
        ...dateIsOptionsWithoutCompareTo,
        ...dateIsOptionsWithDateCompareTo,
        ...dateIsOptionsWithNumericCompareTo,
      ],
      compareToControl: (
        selectedOption:
          | ExpressionIsOptionWithoutCompareTo
          | ExpressionIsOptionWithNumericCompareTo
          | ExpressionIsOptionWithDateCompareTo
      ) => {
        if (
          dateIsOptionsWithoutCompareTo.indexOf(
            selectedOption as ExpressionIsOptionWithoutCompareTo
          ) !== -1
        ) {
          return undefined;
        }
        if (
          dateIsOptionsWithNumericCompareTo.indexOf(
            selectedOption as ExpressionIsOptionWithNumericCompareTo
          ) !== -1
        ) {
          return 'number';
        }
        if (
          dateIsOptionsWithDateCompareTo.indexOf(
            selectedOption as ExpressionIsOptionWithDateCompareTo
          ) !== -1
        ) {
          return 'datepicker';
        }
      },
    };
  }),
  ...dateExpressionWithWithinOptions.map((expression) => {
    return {
      expression,
      options: [
        ...dateIsWithinOptionsWithoutCompareTo,
        ...dateIsWithinOptionsWithCompareTo,
      ],
      compareToControl: (
        selectedOption:
          | ExpressionIsWithinOptionWithoutCompareTo
          | ExpressionIsWithinOptionWithNumericCompareTo
      ) => {
        if (
          dateIsWithinOptionsWithoutCompareTo.indexOf(
            selectedOption as ExpressionIsWithinOptionWithoutCompareTo
          ) !== -1
        ) {
          return undefined;
        }
        if (
          dateIsWithinOptionsWithCompareTo.indexOf(
            selectedOption as ExpressionIsWithinOptionWithNumericCompareTo
          )
        ) {
          return 'number';
        }
      },
    };
  }),
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  {
    expression: 'is not empty',
    options: undefined,
    compareToControl: undefined,
  },
];
const textFilterExpressionOptions = [
  { expression: 'contains', options: undefined, compareToControl: 'text' },
  {
    expression: 'does not contain',
    options: undefined,
    compareToControl: 'text',
  },
  { expression: 'is', options: undefined, compareToControl: 'text' },
  { expression: 'is not', options: undefined, compareToControl: 'text' },
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  {
    expression: 'is not empty',
    options: undefined,
    compareToControl: undefined,
  },
];
const numberFilterExpressionOptions = [
  { expression: '=', options: undefined, compareToControl: 'number' },
  { expression: '!=', options: undefined, compareToControl: 'number' },
  { expression: '<', options: undefined, compareToControl: 'number' },
  { expression: '<=', options: undefined, compareToControl: 'number' },
  { expression: '>', options: undefined, compareToControl: 'number' },
  { expression: '>=', options: undefined, compareToControl: 'number' },
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  {
    expression: 'is not empty',
    options: undefined,
    compareToControl: undefined,
  },
];
export const FieldFilterExpressionOptions: Record<
  FieldType,
  {
    expression: string;
    options: undefined | string[];
    compareToControl:
      | undefined
      | string
      | ((selectedOption: string) => string | undefined);
  }[]
> = {
  text: textFilterExpressionOptions,
  'long-text': textFilterExpressionOptions,
  formula: textFilterExpressionOptions,
  number: numberFilterExpressionOptions,
  date: dateFilterExpressionOptions as any,
  datetime: dateFilterExpressionOptions as any,
  checkbox: [
    { expression: 'is', options: undefined, compareToControl: 'checkbox' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  select: [
    { expression: 'is', options: undefined, compareToControl: 'select' },
    { expression: 'is not', options: undefined, compareToControl: 'select' },
    {
      expression: 'is any of',
      options: undefined,
      compareToControl: 'multi-select',
    },
    {
      expression: 'is none of',
      options: undefined,
      compareToControl: 'multi-select',
    },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  'multi-select': [
    {
      expression: 'has any of',
      options: undefined,
      compareToControl: 'multi-select',
    },
    {
      expression: 'has all of',
      options: undefined,
      compareToControl: 'multi-select',
    },
    {
      expression: 'has none of',
      options: undefined,
      compareToControl: 'multi-select',
    },
    {
      expression: 'is exactly',
      options: undefined,
      compareToControl: 'multi-select',
    },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  'select-user': [
    { expression: 'is me', options: undefined, compareToControl: undefined },
    { expression: 'is', options: undefined, compareToControl: 'select-user' },
    {
      expression: 'is not',
      options: undefined,
      compareToControl: 'select-user',
    },
    {
      expression: 'is any of',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    {
      expression: 'is none of',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  'multi-select-user': [
    { expression: 'has me', options: undefined, compareToControl: undefined },
    {
      expression: 'has any of',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    {
      expression: 'has all of',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    {
      expression: 'has none of',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    {
      expression: 'is exactly',
      options: undefined,
      compareToControl: 'multi-select-user',
    },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  phone: textFilterExpressionOptions,
  email: textFilterExpressionOptions,
  color: textFilterExpressionOptions,
  slider: numberFilterExpressionOptions,
  url: textFilterExpressionOptions,
  attachment: [
    {
      expression: 'filename contains',
      options: undefined,
      compareToControl: 'text',
    },
    {
      expression: 'has file type',
      options: undefined,
      compareToControl: 'select',
    },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    {
      expression: 'is not empty',
      options: undefined,
      compareToControl: undefined,
    },
  ],
  'link-to-record': [],
  'link-to-multiple-records': [],
};

export default FieldFilterExpressionOptions;
