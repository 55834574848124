import { format } from 'date-fns';
import {
  toNumber as _toNumber,
  find as _find,
  filter as _filter,
  map as _map,
  mapValues as _mapValues,
} from 'lodash';
import {
  DataRecord,
  Field,
  FieldSelectOptions,
  FieldType,
  isFieldAttachment,
  isFieldCheckbox,
  isFieldColor,
  isFieldDate,
  isFieldDatetime,
  isFieldEmail,
  isFieldFormula,
  isFieldLinkToMultipleRecords,
  isFieldLinkToRecord,
  isFieldLongText,
  isFieldMultiSelect,
  isFieldMultiSelectUser,
  isFieldNumber,
  isFieldPhone,
  isFieldSelect,
  isFieldSelectUser,
  isFieldSlider,
  isFieldText,
  isFieldURL,
} from '../types';
import {
  FieldAttachmentValue,
  FieldCheckboxValue,
  FieldColorValue,
  FieldDatetimeValue,
  FieldDateValue,
  FieldEmailValue,
  FieldFormulaValue,
  FieldLinkToRecordValue,
  FieldLongTextValue,
  FieldMultiSelectUserValue,
  FieldMultiSelectValue,
  FieldNumberValue,
  FieldPhoneValue,
  FieldSelectUserValue,
  FieldSelectValue,
  FieldSliderValue,
  FieldTextValue,
  FieldURLValue,
  FieldValue,
  isAttachmentArray,
  isString,
  isStringArray,
  PersistedFieldValue,
} from '../types/fieldValues';

/**
 *
 * @param persistedFieldValue : PersistedFieldValue
 * @param field : Field
 * @param userOptions : FieldSelectOptions = []
 * @returns FieldValue
 *
 * This takes the persisted field value which should always
 * be null, string, string[], or Attachment[] and depending
 * on the Field type will return a cast FieldValue with a
 * string display value and a compareValue which can be used
 * in filters and sorting functions
 */
export const castFieldValue = (
  persistedFieldValue: PersistedFieldValue | undefined = {
    internalValue: null,
  },
  field: Field,
  userOptions: FieldSelectOptions = [],
  getLinkedRecord: (
    companyId: string,
    contentTypeSlug: string,
    recordId: string
  ) => Partial<DataRecord> = (
    companyId: string,
    contentTypeSlug: string,
    recordId: string
  ) => ({
    contentType: contentTypeSlug,
    companyId,
    recordId,
    primaryFieldValue: '',
  })
): FieldValue => {
  const compareableFieldValue: FieldValue = {
    ...persistedFieldValue,
    displayValue: '',
    compareValue: undefined,
  };
  try {
    const internalValue = persistedFieldValue.internalValue;
    const fieldType: FieldType = field.type;
    switch (fieldType) {
      case 'text':
        if (isFieldText(field) && isString(internalValue)) {
          (compareableFieldValue as FieldTextValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldTextValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldTextValue;
        }
        if (isFieldText(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldTextValue;
        }
        break;
      case 'long-text':
        if (isFieldLongText(field) && isString(internalValue)) {
          (compareableFieldValue as FieldLongTextValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldLongTextValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldLongTextValue;
        }
        if (isFieldLongText(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldLongTextValue;
        }
        break;
      case 'number':
        if (isFieldNumber(field) && isString(internalValue)) {
          (compareableFieldValue as FieldNumberValue).compareValue =
            _toNumber(internalValue);
          (compareableFieldValue as FieldNumberValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldNumberValue;
        }
        if (isFieldNumber(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldNumberValue;
        }
        break;
      case 'formula':
        if (isFieldFormula(field) && isString(internalValue)) {
          (compareableFieldValue as FieldFormulaValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldFormulaValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldFormulaValue;
        }
        if (isFieldFormula(field) && !isString(internalValue)) {
          return compareableFieldValue;
        }
        break;
      case 'date':
        if (isFieldDate(field) && isString(internalValue)) {
          (compareableFieldValue as FieldDateValue).displayValue =
            'INVALID DATE';
          (compareableFieldValue as FieldDateValue).compareValue = new Date(
            internalValue
          );
          (compareableFieldValue as FieldDateValue).displayValue = format(
            new Date(internalValue),
            'P'
          );
          return compareableFieldValue as FieldDateValue;
        }
        if (isFieldDate(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldDateValue;
        }
        break;
      case 'datetime':
        if (isFieldDatetime(field) && isString(internalValue)) {
          (compareableFieldValue as FieldDatetimeValue).displayValue =
            'INVALID DATE';
          (compareableFieldValue as FieldDatetimeValue).compareValue = new Date(
            internalValue
          );
          (compareableFieldValue as FieldDatetimeValue).displayValue = format(
            new Date(internalValue),
            'Pp'
          );
          return compareableFieldValue as FieldDatetimeValue;
        }
        if (isFieldDatetime(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldDatetimeValue;
        }
        break;
      case 'checkbox':
        if (isFieldCheckbox(field) && isString(internalValue)) {
          const isTruthy = internalValue === 'true' || internalValue === '1';
          (compareableFieldValue as FieldCheckboxValue).compareValue = isTruthy;
          (compareableFieldValue as FieldCheckboxValue).displayValue = isTruthy
            ? 'Yes'
            : 'No';
          return compareableFieldValue as FieldCheckboxValue;
        }
        if (isFieldCheckbox(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldCheckboxValue;
        }
        break;
      case 'slider':
        if (isFieldSlider(field) && isString(internalValue)) {
          (compareableFieldValue as FieldSliderValue).compareValue =
            _toNumber(internalValue);
          (compareableFieldValue as FieldSliderValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldSliderValue;
        }
        if (isFieldSlider(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldSliderValue;
        }
        break;
      case 'select':
        if (isFieldSelect(field) && isString(internalValue)) {
          const selectedOption = _find(field.config.options, {
            value: internalValue,
          });
          if (selectedOption !== undefined) {
            (compareableFieldValue as FieldSelectValue).compareValue =
              selectedOption.value;
            (compareableFieldValue as FieldSelectValue).displayValue =
              selectedOption.label;
          }
          return compareableFieldValue as FieldSelectValue;
        }
        if (isFieldSelect(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldSelectValue;
        }
        break;
      case 'multi-select':
        if (isFieldMultiSelect(field) && isStringArray(internalValue)) {
          const selectedOptions = _filter(field.config.options, (option) =>
            internalValue.includes(option.value)
          );
          (compareableFieldValue as FieldMultiSelectValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldMultiSelectValue).displayValue = _map(
            selectedOptions,
            (option) => option.label
          ).join(',');
          return compareableFieldValue as FieldMultiSelectValue;
        }
        if (isFieldMultiSelect(field) && !isStringArray(internalValue)) {
          return compareableFieldValue as FieldMultiSelectValue;
        }
        break;
      case 'select-user':
        if (isFieldSelectUser(field) && isString(internalValue)) {
          const selectedOption = _find(userOptions, { value: internalValue });
          if (selectedOption !== undefined) {
            (compareableFieldValue as FieldSelectUserValue).compareValue =
              selectedOption.value;
            (compareableFieldValue as FieldSelectUserValue).displayValue =
              selectedOption.label;
          }
          return compareableFieldValue as FieldSelectUserValue;
        }
        if (isFieldSelectUser(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldSelectUserValue;
        }
        break;
      case 'multi-select-user':
        if (isFieldMultiSelectUser(field) && isStringArray(internalValue)) {
          const selectedOptions = _filter(userOptions, (option) =>
            internalValue.includes(option.value)
          );

          (compareableFieldValue as FieldMultiSelectUserValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldMultiSelectUserValue).displayValue =
            _map(selectedOptions, (option) => option.label).join(',');
          return compareableFieldValue as FieldMultiSelectUserValue;
        }
        if (isFieldMultiSelectUser(field) && !isStringArray(internalValue)) {
          return compareableFieldValue as FieldMultiSelectUserValue;
        }
        break;
      case 'email':
        if (isFieldEmail(field) && isString(internalValue)) {
          (compareableFieldValue as FieldEmailValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldEmailValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldEmailValue;
        }
        if (isFieldEmail(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldEmailValue;
        }
        break;
      case 'phone':
        if (isFieldPhone(field) && isString(internalValue)) {
          (compareableFieldValue as FieldPhoneValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldPhoneValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldPhoneValue;
        }
        if (isFieldPhone(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldPhoneValue;
        }
        break;
      case 'color':
        if (isFieldColor(field) && isString(internalValue)) {
          (compareableFieldValue as FieldColorValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldColorValue).displayValue =
            internalValue;
          return compareableFieldValue as FieldColorValue;
        }
        if (isFieldColor(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldColorValue;
        }
        break;
      case 'url':
        if (isFieldURL(field) && isString(internalValue)) {
          (compareableFieldValue as FieldURLValue).compareValue = internalValue;
          (compareableFieldValue as FieldURLValue).displayValue = internalValue;
          return compareableFieldValue as FieldURLValue;
        }
        if (isFieldURL(field) && !isString(internalValue)) {
          return compareableFieldValue as FieldURLValue;
        }
        break;
      case 'attachment':
        if (isFieldAttachment(field) && isAttachmentArray(internalValue)) {
          (compareableFieldValue as FieldAttachmentValue).compareValue =
            internalValue;
          (compareableFieldValue as FieldAttachmentValue).displayValue = _map(
            internalValue,
            (attachment) => attachment.original.name
          ).join(',');
          return compareableFieldValue as FieldAttachmentValue;
        }
        if (isFieldAttachment(field) && !isAttachmentArray(internalValue)) {
          return compareableFieldValue as FieldAttachmentValue;
        }
        break;
      case 'link-to-record':
        if (isFieldLinkToRecord(field) && isString(internalValue)) {
          try {
            const record = getLinkedRecord(
              field.config.companyId,
              field.config.contentTypeSlug,
              internalValue
            );
            compareableFieldValue.compareValue = record.primaryFieldValue;
            compareableFieldValue.displayValue = record.primaryFieldValue ?? '';
            compareableFieldValue.internalValue = internalValue;
          } catch (error) {}

          return compareableFieldValue as FieldLinkToRecordValue;
        }
        break;
      case 'link-to-multiple-records':
        if (
          isFieldLinkToMultipleRecords(field) &&
          isStringArray(internalValue)
        ) {
          try {
            const records = internalValue.map((recordId) =>
              getLinkedRecord(
                field.config.companyId,
                field.config.contentTypeSlug,
                recordId
              )
            );
            compareableFieldValue.compareValue = records.map(
              (record) => record.primaryFieldValue ?? ''
            );
            compareableFieldValue.displayValue =
              compareableFieldValue.compareValue.join(',');
            compareableFieldValue.internalValue = internalValue;
          } catch (error) {}

          return compareableFieldValue as FieldLinkToRecordValue;
        }
        break;
      default:
        throw new Error(
          `Unhandled field type ${fieldType} for ${field.name as any}`
        );
    }
  } catch (error: any) {
    if (error.message.indexOf('Unhandled field type') !== -1) {
      throw error;
    }
  }
  return compareableFieldValue;
};

export const castFieldValueToPersistedFieldValue = (
  fieldValue: FieldValue
): PersistedFieldValue => {
  if (fieldValue === null) {
    return { internalValue: null };
  }
  return { internalValue: fieldValue.internalValue };
};

export const castEnrichedFieldsToPersistedFields = (
  enrichedFields: Record<string, FieldValue>
): Record<string, PersistedFieldValue> => {
  return _mapValues(enrichedFields, castFieldValueToPersistedFieldValue);
};
