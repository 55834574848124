export type FieldType =
  | 'long-text'
  | 'text'
  | 'number'
  | 'formula'
  | 'datetime'
  | 'date'
  | 'phone'
  | 'email'
  | 'url'
  | 'attachment'
  | 'select'
  | 'multi-select'
  | 'select-user'
  | 'multi-select-user'
  | 'checkbox'
  | 'slider'
  | 'color'
  | 'link-to-record'
  | 'link-to-multiple-records';
export type FieldTextConfig = {
  defaultValue: string | null;
};
export type FieldText = {
  fieldId: string;
  type: 'text';
  name: string;
  config: FieldTextConfig;
};
export const isFieldText = (field: Field): field is FieldText => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'text';
};

export type FieldLongText = {
  fieldId: string;
  type: 'long-text';
  name: string;
  config: FieldTextConfig;
};
export const isFieldLongText = (field: Field): field is FieldLongText => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'long-text';
};
export type FieldNumberConfig = {
  defaultValue: number | null;
};
export type FieldNumber = {
  fieldId: string;
  type: 'number';
  name: string;
  config: FieldNumberConfig;
};
export const isFieldNumber = (field: Field): field is FieldNumber => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'number';
};
export type FieldCheckboxConfig = {
  defaultValue: boolean | null;
};
export type FieldCheckbox = {
  fieldId: string;
  type: 'checkbox';
  name: string;
  config: FieldCheckboxConfig;
};
export const isFieldCheckbox = (field: Field): field is FieldCheckbox => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'checkbox';
};
export type FieldColor = {
  fieldId: string;
  type: 'color';
  name: string;
  config: FieldTextConfig;
};
export const isFieldColor = (field: Field): field is FieldColor => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'color';
};
export type FieldFormulaConfig = {
  templateString: string;
};
export type FieldFormula = {
  fieldId: string;
  type: 'formula';
  name: string;
  config: FieldFormulaConfig;
};
export const isFieldFormula = (field: Field): field is FieldFormula => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'formula';
};
export type FieldSelectOption = { label: string; value: string };
export type FieldSelectOptions = FieldSelectOption[];
export type FieldSelectConfig = {
  defaultValue: string | null;
  options: FieldSelectOptions;
};
export type FieldSelect = {
  fieldId: string;
  type: 'select';
  name: string;
  config: {
    defaultValue: string | null;
    options: FieldSelectOptions;
  };
};
export const isFieldSelect = (field: Field): field is FieldSelect => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'select';
};
export type FieldMultiSelectConfig = {
  defaultValue: string[] | null;
  options: { label: string; value: string }[];
};
export type FieldMultiSelect = {
  fieldId: string;
  name: string;
  type: 'multi-select';
  config: FieldMultiSelectConfig;
};
export const isFieldMultiSelect = (field: Field): field is FieldMultiSelect => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'multi-select';
};
export type FieldSelectUserConfig = {
  defaultValue: string | null;
};
export type FieldSelectUser = {
  fieldId: string;
  type: 'select-user';
  name: string;
  config: FieldSelectUserConfig;
};
export const isFieldSelectUser = (field: Field): field is FieldSelectUser => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'select-user';
};
export type FieldMultiSelectUserConfig = {
  defaultValue: string[] | null;
};
export type FieldMultiSelectUser = {
  fieldId: string;
  name: string;
  type: 'multi-select-user';
  config: FieldMultiSelectUserConfig;
};
export const isFieldMultiSelectUser = (
  field: Field
): field is FieldMultiSelectUser => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'multi-select-user';
};
export type FieldSliderConfig = {
  defaultValue: string | null;
  min: number | null;
  max: number | null;
};
export type FieldSlider = {
  fieldId: string;
  name: string;
  type: 'slider';
  config: FieldSliderConfig;
};
export const isFieldSlider = (field: Field): field is FieldSlider => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'slider';
};
export type FieldDateConfig = {
  defaultValue: string | null;
  min: string | null;
  max: string | null;
  googleCalendarId?: string | null;
};

export type FieldDatetime = {
  fieldId: string;
  name: string;
  type: 'datetime';
  config: FieldDateConfig;
};
export const isFieldDatetime = (field: Field): field is FieldDatetime => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'datetime';
};
export type FieldDate = {
  fieldId: string;
  name: string;
  type: 'date';
  config: FieldDateConfig;
};

export const isFieldDate = (field: Field): field is FieldDatetime => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'date';
};
export type FieldPhone = {
  fieldId: string;
  name: string;
  type: 'phone';
  config: FieldTextConfig;
};
export const isFieldPhone = (field: Field): field is FieldPhone => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'phone';
};
export type FieldEmail = {
  fieldId: string;
  name: string;
  type: 'email';
  config: FieldTextConfig;
};
export const isFieldEmail = (field: Field): field is FieldEmail => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'email';
};
export type FieldURL = {
  fieldId: string;
  name: string;
  type: 'url';
  config: FieldTextConfig;
};
export const isFieldURL = (field: Field): field is FieldURL => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'url';
};
export type FieldAttachmentType =
  | 'plain-text'
  | 'image'
  | 'audio'
  | 'video'
  | 'pdf'
  | 'rich-text'
  | 'presentation'
  | 'spreadsheet'
  | 'archive'
  | 'code'
  | 'csv'
  | 'markup';
export type FieldAttachmentConfig = {
  fileType: FieldAttachmentType[] | null;
  minNumberOfFiles: number | null;
  maxNumberOfFiles: number | null;
  maxTotalFileSizeKB: number | null;
  minSizeKB: number | null;
  maxSizeKB: number | null;

  minImageWidth: number | null;
  minImageHeight: number | null;
  maxImageWidth: number | null;
  maxImageHeight: number | null;
};

export type FieldAttachment = {
  fieldId: string;
  name: string;
  type: 'attachment';
  config: FieldAttachmentConfig;
};
export const isFieldAttachment = (field: Field): field is FieldAttachment => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'attachment';
};

export type FieldLinkToRecordConfig = {
  companyId: string;
  contentTypeSlug: string;
};
export type FieldLinkToRecord = {
  fieldId: string;
  type: 'link-to-record';
  name: string;
  config: FieldLinkToRecordConfig;
};
export const isFieldLinkToRecord = (
  field: Field
): field is FieldLinkToRecord => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'link-to-record';
};
export type FieldLinkToMultipleRecordsConfig = {
  companyId: string;
  contentTypeSlug: string;
};
export type FieldLinkToMultipleRecords = {
  fieldId: string;
  type: 'link-to-multiple-records';
  name: string;
  config: FieldLinkToMultipleRecordsConfig;
};
export const isFieldLinkToMultipleRecords = (
  field: Field
): field is FieldLinkToMultipleRecords => {
  if (field === undefined) {
    return false;
  }
  return field.type === 'link-to-multiple-records';
};

export type FieldConfig =
  | FieldTextConfig
  | FieldFormulaConfig
  | FieldNumberConfig
  | FieldCheckboxConfig
  | FieldDateConfig
  | FieldSliderConfig
  | FieldSelectConfig
  | FieldMultiSelectConfig
  | FieldSelectUserConfig
  | FieldMultiSelectUserConfig
  | FieldAttachmentConfig
  | FieldLinkToMultipleRecordsConfig
  | FieldLinkToRecordConfig;

export type Field =
  | FieldLongText
  | FieldText
  | FieldFormula
  | FieldNumber
  | FieldCheckbox
  | FieldColor
  | FieldSelect
  | FieldMultiSelect
  | FieldSelectUser
  | FieldMultiSelectUser
  | FieldSlider
  | FieldDatetime
  | FieldDate
  | FieldEmail
  | FieldPhone
  | FieldURL
  | FieldAttachment
  | FieldLinkToRecord
  | FieldLinkToMultipleRecords;
