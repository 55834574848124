export type OPERATOR_OPTIONS = 'and' | 'or';

export type EMPTY_FILTER_EXPRESSION_OPTIONS = 'is empty' | 'is not empty';
export type TEXT_FILTER_EXPRESSION_OPTIONS =
  | 'contains'
  | 'does not contain'
  | 'is'
  | 'is not'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;
export type NUMBER_FILTER_EXPRESSION_OPTIONS =
  | '='
  | '!='
  | '<'
  | '>'
  | '<='
  | '>='
  | EMPTY_FILTER_EXPRESSION_OPTIONS;

export type SELECT_FILTER_EXPRESSION_OPTIONS =
  | 'is'
  | 'is not'
  | 'is any of'
  | 'is none of'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;

export type MULTI_SELECT_FILTER_EXPRESSION_OPTIONS =
  | 'has any of'
  | 'has all of'
  | 'has none of'
  | 'is exactly'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;

export type USER_SELECT_FILTER_EXPRESSION_OPTIONS =
  | 'is me'
  | EMPTY_FILTER_EXPRESSION_OPTIONS
  | SELECT_FILTER_EXPRESSION_OPTIONS;
export type USER_MULTI_SELECT_FILTER_EXPRESSION_OPTIONS =
  | 'has me'
  | EMPTY_FILTER_EXPRESSION_OPTIONS
  | MULTI_SELECT_FILTER_EXPRESSION_OPTIONS;

export type ATTACHMENT_FILTER_EXPRESSION_OPTIONS =
  | 'filename contains'
  | 'has file type'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;
export type CHECKBOX_FILTER_EXPRESSION_OPTIONS =
  | 'is'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;
export type DATE_FILTER_EXPRESSION_OPTIONS =
  | 'is'
  | 'is within'
  | 'is before'
  | 'is after'
  | 'is on or before'
  | 'is on or after'
  | 'is not'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;
export type DATETIME_FILTER_EXPRESSION_OPTIONS =
  | 'is'
  | 'is within'
  | 'is before'
  | 'is after'
  | 'is on or before'
  | 'is on or after'
  | 'is not'
  | EMPTY_FILTER_EXPRESSION_OPTIONS;
export type FILTER_EXPRESSION =
  | EMPTY_FILTER_EXPRESSION_OPTIONS
  | TEXT_FILTER_EXPRESSION_OPTIONS
  | NUMBER_FILTER_EXPRESSION_OPTIONS
  | USER_SELECT_FILTER_EXPRESSION_OPTIONS
  | USER_MULTI_SELECT_FILTER_EXPRESSION_OPTIONS
  | SELECT_FILTER_EXPRESSION_OPTIONS
  | MULTI_SELECT_FILTER_EXPRESSION_OPTIONS
  | ATTACHMENT_FILTER_EXPRESSION_OPTIONS
  | CHECKBOX_FILTER_EXPRESSION_OPTIONS
  | DATE_FILTER_EXPRESSION_OPTIONS
  | DATETIME_FILTER_EXPRESSION_OPTIONS;
export type TEXT_COMPARE_TO = string | undefined;
export interface FilterConditionBase {
  fieldId: string;
  expression: FILTER_EXPRESSION;
  compareTo?: any;
  option?: any;
}
export interface AllFieldsFilterBy extends FilterConditionBase {
  expression: 'is empty' | 'is not empty';
  compareTo: undefined;
  option: undefined;
}
export interface TextFilterByString extends FilterConditionBase {
  expression: 'contains' | 'does not contain' | 'is' | 'is not';
  compareTo: string | undefined;
}
export interface UserSelectFilterBy extends FilterConditionBase {
  expression: 'is me';
  compareTo: undefined;
}
export interface UserMultiSelectFilterBy extends FilterConditionBase {
  expression: 'has me';
  compareTo: undefined;
}
export interface SelectFilterBySingleOption extends FilterConditionBase {
  expression: 'is' | 'is not';
  compareTo: string | undefined;
}
export interface SelectFilterByMultipleOptions extends FilterConditionBase {
  expression: 'is any of' | 'is none of';
  compareTo: string[];
}
export interface MultipleFilterBy extends FilterConditionBase {
  expression: 'has any of' | 'has all of' | 'has none of' | 'is exactly';
  compareTo: string[];
}
export interface NumberFilterByValue extends FilterConditionBase {
  expression: '=' | '!=' | '<' | '>' | '<=' | '>=';
  compareTo: number | undefined;
}
export interface CheckboxFilterBy extends FilterConditionBase {
  expression: 'is';
  compareTo: boolean;
}
export interface DateFilterByIs extends FilterConditionBase {
  expression:
    | 'is'
    | 'is not'
    | 'is before'
    | 'is after'
    | 'is on or before'
    | 'is on or after';
  compareTo: undefined;
  option:
    | 'today'
    | 'today'
    | 'tomorrow'
    | 'one week ago'
    | 'one week from now'
    | 'one month ago'
    | 'one month from now';
}
export interface DateFilterByIsWithCompareTo extends FilterConditionBase {
  expression:
    | 'is'
    | 'is not'
    | 'is before'
    | 'is after'
    | 'is on or before'
    | 'is on or after';
  option: 'number of days ago' | 'number of days from now' | 'exact date...';
  compareTo: string | number | undefined;
}
export interface DateFilterByIsWithin extends FilterConditionBase {
  expression: 'is within';
  option:
    | 'the past week'
    | 'the past month'
    | 'the past year'
    | 'the next week'
    | 'the next month'
    | 'the next year';
  compareTo: undefined;
}
export interface DateFilterByIsWithinWithCompareTo extends FilterConditionBase {
  expression: 'is within';
  option: 'the next number of days' | 'the past number of days';
  compareTo: number | undefined;
}

export type TextFilterCondition = TextFilterByString | AllFieldsFilterBy;
export type NumberFilterCondition = NumberFilterByValue | AllFieldsFilterBy;
export type SelectFilterCondition =
  | SelectFilterBySingleOption
  | SelectFilterByMultipleOptions
  | AllFieldsFilterBy;
export type UserSelectFilterCondition =
  | SelectFilterCondition
  | UserSelectFilterBy;
export type MultiSelectFilterCondition = AllFieldsFilterBy | MultipleFilterBy;
export type UsersMultiSelectFilterCondition =
  | MultiSelectFilterCondition
  | UserMultiSelectFilterBy;
export type CheckboxFilterCondition = AllFieldsFilterBy | CheckboxFilterBy;
export type DateFilterCondition =
  | AllFieldsFilterBy
  | DateFilterByIs
  | DateFilterByIsWithCompareTo
  | DateFilterByIsWithin
  | DateFilterByIsWithinWithCompareTo;
export type FilterCondition =
  | AllFieldsFilterBy
  | TextFilterCondition
  | NumberFilterCondition
  | SelectFilterCondition
  | MultiSelectFilterCondition
  | UserSelectFilterCondition
  | UsersMultiSelectFilterCondition
  | CheckboxFilterCondition
  | DateFilterCondition;

export const isFilterCondition = (
  maybeFilterCondition: unknown
): maybeFilterCondition is FilterCondition => {
  return (maybeFilterCondition as FilterCondition).expression !== undefined;
};
export const isFilterConditionGroup = (
  maybeFilterConditionGroup: unknown
): maybeFilterConditionGroup is FilterConditionGroup => {
  return (
    (maybeFilterConditionGroup as FilterConditionGroup).operator !==
      undefined &&
    (maybeFilterConditionGroup as FilterConditionGroup).conditions !== undefined
  );
};

export interface FilterConditionGroup {
  operator: OPERATOR_OPTIONS;
  conditions: (FilterCondition | FilterConditionGroup)[];
}
