import { Attachment, isAttachment } from './attachments';

export interface PersistedFieldValue {
  internalValue: string | string[] | null | Attachment[];
  errors?: string[];
}

export const isAttachmentArray = (value: unknown): value is Attachment[] => {
  if (value === undefined || value === null || !Array.isArray(value)) {
    return false;
  }
  try {
    return value.every((val) => isAttachment(val));
  } catch (error) {}
  return false;
};
export const isStringArray = (value: unknown): value is string[] => {
  if (value === undefined || value === null || !Array.isArray(value)) {
    return false;
  }
  try {
    return value.every((val) => typeof val === 'string');
  } catch (error) {}
  return false;
};
export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export interface FieldTextValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldLongTextValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldFormulaValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldNumberValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: number | undefined;
}
export interface FieldCheckboxValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: boolean | undefined;
}
export interface FieldColorValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldSelectValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldMultiSelectValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string[] | undefined;
}
export interface FieldSelectUserValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldMultiSelectUserValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string[] | undefined;
}
export interface FieldSliderValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: number | undefined;
}
export interface FieldDatetimeValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: Date | undefined;
}
export interface FieldDateValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: Date | undefined;
}
export interface FieldEmailValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldPhoneValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldURLValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldAttachmentValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: Attachment[] | undefined;
}
export interface FieldLinkToRecordValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string | undefined;
}
export interface FieldLinkToMultipleRecordsValue extends PersistedFieldValue {
  displayValue: string;
  compareValue: string[] | undefined;
}

export type FieldValue =
  | null
  | FieldLongTextValue
  | FieldTextValue
  | FieldFormulaValue
  | FieldNumberValue
  | FieldCheckboxValue
  | FieldColorValue
  | FieldSelectValue
  | FieldMultiSelectValue
  | FieldSelectUserValue
  | FieldMultiSelectUserValue
  | FieldSliderValue
  | FieldDatetimeValue
  | FieldDateValue
  | FieldEmailValue
  | FieldPhoneValue
  | FieldURLValue
  | FieldAttachmentValue
  | FieldLinkToRecordValue
  | FieldLinkToMultipleRecordsValue;
