import {
  DataRecord,
  Field,
  FilterCondition,
  FilterConditionGroup,
  isFilterCondition,
  isFilterConditionGroup,
  User,
  FieldType,
  EnrichedDataRecord,
  FieldSelectOptions,
} from '../../types';

import {
  get as _get,
  every as _every,
  some as _some,
  map as _map,
} from 'lodash';

import { getNumberFilterFunction } from './fields/numberFilterFunctions';
import { getDateFilteringFunction } from './fields/dateFilterFunctions';
import { getTextFilteringFunction } from './fields/textFilterFunctions';
import { getCheckboxFilteringFunction } from './fields/checkboxFilterFunctions';
import { getSelectFilteringFunction } from './fields/selectFilteringFunctions';
import { getMultiSelectFilteringFunction } from './fields/multiSelectFilteringFunctions';
import { getAttachmentFilteringFunction } from './fields/attachmentFilteringFunctions';
import { castUsersToFieldSelectOptions } from '../castUsers';

export type FieldFilterFunction = (record: EnrichedDataRecord) => boolean;

export const getFilterFunction = (
  condition: FilterConditionGroup | FilterCondition,
  fields: Record<string, Field>,
  userOptions: FieldSelectOptions,
  currentUser?: User
): FieldFilterFunction => {
  if (isFilterCondition(condition)) {
    return getFilterConditionFunction(
      condition,
      fields,
      userOptions,
      currentUser
    );
  }

  return (record: EnrichedDataRecord) => {
    if (!isFilterConditionGroup(condition)) {
      return true;
    }
    const { conditions, operator } = condition;

    if (conditions.length === 0) {
      return true;
    }

    const conditionFuncs = _map(conditions, (conditionIn) =>
      getFilterFunction(conditionIn, fields, userOptions, currentUser)
    );
    if (operator === 'and') {
      return _every(conditionFuncs.map((func) => func(record)));
    }

    if (operator === 'or') {
      return _some(conditionFuncs.map((func) => func(record)));
    }

    return true;
  };
};

export const getFilterConditionFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>,
  userOptions: FieldSelectOptions,
  currentUser?: User
): FieldFilterFunction => {
  const field = _get(fields, condition.fieldId);
  if (field === undefined) {
    return (record: DataRecord) => true;
  }
  const fieldType: FieldType = field.type;
  switch (fieldType) {
    case 'text':
      return getTextFilteringFunction(condition, fields);
    case 'long-text':
      return getTextFilteringFunction(condition, fields);
    case 'number':
      return getNumberFilterFunction(condition, fields);
    case 'formula':
      return getTextFilteringFunction(condition, fields);
    case 'date':
      return getDateFilteringFunction(condition, fields);
    case 'datetime':
      return getDateFilteringFunction(condition, fields);
    case 'checkbox':
      return getCheckboxFilteringFunction(condition, fields);
    case 'slider':
      return getNumberFilterFunction(condition, fields);
    case 'select':
      return getSelectFilteringFunction(condition, fields);
    case 'multi-select':
      return getMultiSelectFilteringFunction(condition, fields);
    case 'select-user':
      return getSelectFilteringFunction(condition, fields);
    case 'multi-select-user':
      return getMultiSelectFilteringFunction(condition, fields);
    case 'email':
      return getTextFilteringFunction(condition, fields);
    case 'phone':
      return getTextFilteringFunction(condition, fields);
    case 'color':
      return getTextFilteringFunction(condition, fields);
    case 'url':
      return getTextFilteringFunction(condition, fields);
    case 'attachment':
      return getAttachmentFilteringFunction(condition, fields);
    default:
      throw new Error(
        `Unhandled field type ${fieldType} for ${field.name as any}`
      );
  }
};

export const filterRecords = (
  records: EnrichedDataRecord[],
  condition: FilterConditionGroup | FilterCondition,
  fields: Record<string, Field>,
  users: User[],
  currentUser?: User
) => {
  const userOptions = castUsersToFieldSelectOptions(users);
  const filterFunc = getFilterFunction(
    condition,
    fields,
    userOptions,
    currentUser
  );
  return records.filter(filterFunc);
};

export const isRecordFilteredIn = (
  fields: Record<string, Field>,
  record: EnrichedDataRecord,
  filter: FilterConditionGroup,
  users: User[],
  currentUser?: User
): boolean => {
  const userOptions = castUsersToFieldSelectOptions(users);
  const filterFunc = getFilterFunction(
    filter,
    fields,
    userOptions,
    currentUser
  );
  return filterFunc(record);
};
