import { get as _get } from 'lodash';
import {
  isWithinInterval,
  startOfDay,
  endOfDay,
  addMonths,
  addDays,
  addWeeks,
  addYears,
  subMonths,
  subDays,
  subWeeks,
  subYears,
  isToday,
  isTomorrow,
  isSameDay,
  isValid,
  isAfter,
  isBefore,
} from 'date-fns';

import { FilterCondition, Field, EnrichedDataRecord } from '../../../types';
import {
  ExpressionWithIsWithinOption,
  ExpressionIsWithinOptionWithNumericCompareTo,
  ExpressionIsWithinOptionWithoutCompareTo,
  ExpressionWithIsOption,
  ExpressionIsOptionWithoutCompareTo,
  ExpressionIsOptionWithNumericCompareTo,
  ExpressionIsOptionWithDateCompareTo,
  dateExpressionWithIsOptions,
  dateExpressionWithWithinOptions,
} from '../filterExpressionConstants';
import { FieldFilterFunction } from '../filteringCore';
import { FieldDateValue } from '../../../types/fieldValues';

export const getDateExpressionWithIsWithinOptionsFilterFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
): FieldFilterFunction => {
  const withIsWithinFunctions: Record<
    ExpressionWithIsWithinOption,
    Record<
      | ExpressionIsWithinOptionWithNumericCompareTo
      | ExpressionIsWithinOptionWithoutCompareTo,
      (dateIn: Date, compareTo: string | number | undefined) => boolean
    >
  > = {
    'is within': {
      'the next month': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) =>
        isWithinInterval(dateIn, {
          start: startOfDay(new Date()),
          end: endOfDay(addMonths(new Date(), 1)),
        }),
      'the next number of days': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined) return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isWithinInterval(dateIn, {
          start: startOfDay(new Date()),
          end: endOfDay(addDays(new Date(), numberIn)),
        });
      },
      'the next week': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, {
          start: startOfDay(new Date()),
          end: endOfDay(addWeeks(new Date(), 1)),
        }),
      'the next year': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, {
          start: startOfDay(new Date()),
          end: endOfDay(addYears(new Date(), 1)),
        }),
      'the past month': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) =>
        isWithinInterval(dateIn, {
          start: startOfDay(subMonths(new Date(), 1)),
          end: startOfDay(new Date()),
        }),
      'the past number of days': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined) return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isWithinInterval(dateIn, {
          start: startOfDay(subDays(new Date(), numberIn)),
          end: startOfDay(new Date()),
        });
      },
      'the past week': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, {
          start: startOfDay(subWeeks(new Date(), 1)),
          end: startOfDay(new Date()),
        }),
      'the past year': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, {
          start: startOfDay(subYears(new Date(), 1)),
          end: startOfDay(new Date()),
        }),
    },
  };
  return (record: EnrichedDataRecord) => {
    const value = _get(record, [
      'enrichedFields',
      condition.fieldId,
    ]) as FieldDateValue;
    if (condition.option === undefined) {
      return true;
    }
    if (
      value === undefined ||
      value.compareValue === undefined ||
      !condition.option
    ) {
      return false;
    }
    const compareValue = value.compareValue;

    const comparisonFunc =
      withIsWithinFunctions[
        condition.expression as ExpressionWithIsWithinOption
      ][
        condition.option as
          | ExpressionIsWithinOptionWithoutCompareTo
          | ExpressionIsWithinOptionWithNumericCompareTo
      ];
    return comparisonFunc(
      compareValue,
      condition.compareTo as string | undefined
    );
  };
};
const getDateExpressionWithIsOptionsFilterFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>
): FieldFilterFunction => {
  const withIsFunctions: Record<
    ExpressionWithIsOption,
    Record<
      | ExpressionIsOptionWithoutCompareTo
      | ExpressionIsOptionWithNumericCompareTo
      | ExpressionIsOptionWithDateCompareTo,
      (dateIn: Date, compareTo: string | number | undefined) => boolean
    >
  > = {
    is: {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        isToday(dateIn),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isTomorrow(dateIn),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, subWeeks(new Date(), 1)),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isSameDay(dateIn, addWeeks(new Date(), 1)),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, subMonths(new Date(), 1)),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isSameDay(dateIn, addMonths(new Date(), 1)),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isSameDay(dateIn, subDays(new Date(), numberIn));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isSameDay(dateIn, addDays(new Date(), numberIn));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isSameDay(dateIn, compareDateIn);
      },
    },
    'is after': {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(subWeeks(new Date(), 1))),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isAfter(dateIn, endOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(subMonths(new Date(), 1))),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isAfter(dateIn, endOfDay(addMonths(new Date(), 1))),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, endOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, endOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isAfter(dateIn, endOfDay(compareDateIn));
      },
    },
    'is before': {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(subWeeks(new Date(), 1))),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isBefore(dateIn, startOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(subMonths(new Date(), 1))),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isBefore(dateIn, startOfDay(addMonths(new Date(), 1))),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, startOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, startOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isBefore(dateIn, startOfDay(compareDateIn));
      },
    },
    'is not': {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        !isToday(dateIn),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        !isTomorrow(dateIn),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, subWeeks(new Date(), 1)),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => !isSameDay(dateIn, addWeeks(new Date(), 1)),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, subMonths(new Date(), 1)),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => !isSameDay(dateIn, addMonths(new Date(), 1)),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return !isSameDay(dateIn, subDays(new Date(), numberIn));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return !isSameDay(dateIn, addDays(new Date(), numberIn));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return !isSameDay(dateIn, compareDateIn);
      },
    },
    'is on or after': {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(subWeeks(new Date(), 1))),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isAfter(dateIn, startOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(subMonths(new Date(), 1))),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isAfter(dateIn, startOfDay(addMonths(new Date(), 1))),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, startOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, startOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isAfter(dateIn, startOfDay(compareDateIn));
      },
    },
    'is on or before': {
      today: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(subWeeks(new Date(), 1))),
      'one week from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isBefore(dateIn, endOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(subMonths(new Date(), 1))),
      'one month from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => isBefore(dateIn, endOfDay(addMonths(new Date(), 1))),
      'number of days ago': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, endOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const numberIn =
          typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, endOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (
        dateIn: Date,
        compareTo: string | number | undefined
      ) => {
        if (compareTo === undefined || compareTo === null || compareTo === '')
          return true;
        const compareDateIn =
          typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isBefore(dateIn, endOfDay(compareDateIn));
      },
    },
  };
  return (record: EnrichedDataRecord) => {
    const value = _get(record, [
      'enrichedFields',
      condition.fieldId,
    ]) as FieldDateValue;
    if (value === undefined) {
      return false;
    }
    const compareValue = value.compareValue;

    const comparisonFunc =
      withIsFunctions[condition.expression as ExpressionWithIsOption][
        condition.option as
          | ExpressionIsOptionWithoutCompareTo
          | ExpressionIsOptionWithDateCompareTo
          | ExpressionIsOptionWithNumericCompareTo
      ];
    return comparisonFunc(
      compareValue as Date,
      condition.compareTo as string | undefined
    );
  };
};

export const getDateFilteringFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>
): FieldFilterFunction => {
  if (
    dateExpressionWithIsOptions.indexOf(
      condition.expression as ExpressionWithIsOption
    ) !== -1
  ) {
    return getDateExpressionWithIsOptionsFilterFunction(condition, fields);
  }
  if (
    dateExpressionWithWithinOptions.indexOf(
      condition.expression as ExpressionWithIsWithinOption
    ) !== -1
  ) {
    return getDateExpressionWithIsWithinOptionsFilterFunction(
      condition,
      fields
    );
  }
  if (condition.expression === 'is empty') {
    return (record: EnrichedDataRecord) => {
      const value = _get(record, [
        'enrichedFields',
        condition.fieldId,
      ]) as FieldDateValue;

      return value === undefined || value.compareValue === undefined;
    };
  }
  if (condition.expression === 'is not empty') {
    return (record: EnrichedDataRecord) => {
      const value = _get(record, [
        'enrichedFields',
        condition.fieldId,
      ]) as FieldDateValue;

      return value !== undefined && value.compareValue !== undefined;
    };
  }

  return () => true;
};
