import { get as _get, isEqual as _isEqual } from 'lodash';

import {
  FilterCondition,
  Field,
  EnrichedDataRecord,
  MULTI_SELECT_FILTER_EXPRESSION_OPTIONS,
} from '../../../types';
import {
  FieldMultiSelectValue,
  isStringArray,
} from '../../../types/fieldValues';
import { FieldFilterFunction } from '../filteringCore';

export const getMultiSelectFilteringFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
): FieldFilterFunction => {
  const compareTo = condition.compareTo;
  const expression =
    condition.expression as MULTI_SELECT_FILTER_EXPRESSION_OPTIONS;
  switch (expression) {
    case 'has all of':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        if (compareValue === undefined) {
          return false;
        }
        return compareTo.every((cp) =>
          (value.compareValue as string[]).includes(cp)
        );
      };
    case 'has any of':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        if (compareValue === undefined) {
          return false;
        }
        return compareTo.some((cp) => compareValue.includes(cp));
      };
    case 'has none of':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;
        if (value === undefined) {
          return true;
        }
        const compareValue = value.compareValue;
        if (compareValue === undefined) {
          return true;
        }
        return !compareTo.some((cp) => compareValue.includes(cp));
      };
    case 'is exactly':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        if (compareValue === undefined) {
          return false;
        }
        return _isEqual(compareValue, compareTo);
      };
    case 'is empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;

        return (
          value === undefined ||
          value.compareValue === undefined ||
          _isEqual(value.compareValue, [])
        );
      };
    case 'is not empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldMultiSelectValue;

        return (
          value !== undefined &&
          value.compareValue !== undefined &&
          !_isEqual(value.compareValue, [])
        );
      };
    default:
      throw new Error(`Unhandled text filter for expression ${expression} }`);
  }
};
