import { get as _get } from 'lodash';

import {
  FilterCondition,
  Field,
  EnrichedDataRecord,
  ATTACHMENT_FILTER_EXPRESSION_OPTIONS,
} from '../../../types';
import { FieldAttachmentValue, isString } from '../../../types/fieldValues';
import { FieldFilterFunction } from '../filteringCore';

export const getAttachmentFilteringFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
): FieldFilterFunction => {
  const expression =
    condition.expression as ATTACHMENT_FILTER_EXPRESSION_OPTIONS;
  switch (expression) {
    case 'is empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldAttachmentValue;
        if (value === undefined) {
          return true;
        }
        const compareValue = value.compareValue;
        return compareValue === undefined || compareValue.length === 0;
      };
    case 'is not empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldAttachmentValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        return compareValue !== undefined && compareValue.length !== 0;
      };
    case 'filename contains':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldAttachmentValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        const compareTo = condition.compareTo;
        if (!isString(compareTo)) {
          return true;
        }
        if (compareValue === undefined) {
          return true;
        }
        return compareValue.some((att) =>
          att.original.name.includes(compareTo)
        );
      };
    case 'has file type':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldAttachmentValue;
        if (value === undefined) {
          return false;
        }
        const compareValue = value.compareValue;
        const compareTo = condition.compareTo;
        if (compareValue === undefined || !isString(compareTo)) {
          return true;
        }
        return compareValue.some((att) => att.original.type === compareTo);
      };
    default:
      throw new Error(
        `Unhandled attachment filter for expression ${expression} }`
      );
  }
};
