import { get as _get } from 'lodash';
import { FilterConditionGroup } from './filters';

export type RecordAutomationDataTemplate = string;

/**
 * The record automation action is basically an API view for the actions content type
 * and links to that api view to this specific automation
 * it also persists the expected output to help build the next step
 */
export interface RecordAutomationAction {
  automationId: string;
  automationActionId: string;
  // note that these will have to be configured to enable the automation
  integrationId?: string;
  integrationActionId?: string;
  // dependencies: string[];
  apiViewId?: string;
  cachedTestOutputs?: {
    [responseKey: string]: string;
  };
}

export type RecordAutomationTriggerType =
  | 'new-record-automation'
  | 'new-record-with-filter-automation'
  | 'new-record-in-view-automation'
  | 'updated-record-automation'
  | 'updated-record-with-filter-automation'
  | 'updated-record-in-view-automation'
  | 'deleted-record-automation'
  | 'deleted-record-with-filter-automation'
  | 'scheduled-with-filter-automation'
  | 'scheduled-in-view-automation';

export const AutomationTypeSelectOptions: {
  value: RecordAutomationTriggerType;
  label: string;
}[] = [
  {
    value: 'scheduled-in-view-automation',
    label: 'Scheduled Automation on Records in View',
  },
  {
    value: 'scheduled-with-filter-automation',
    label: 'Scheduled Automation on Records with Filter',
  },
  { value: 'new-record-automation', label: 'New Record' },
  {
    value: 'new-record-with-filter-automation',
    label: 'New Record with Filter',
  },
  { value: 'new-record-in-view-automation', label: 'New Record in View' },
  { value: 'updated-record-automation', label: 'Updated Record' },
  {
    value: 'updated-record-with-filter-automation',
    label: 'Updated Record with Filter',
  },
  {
    value: 'updated-record-in-view-automation',
    label: 'Updated Record in View',
  },
  { value: 'deleted-record-automation', label: 'Deleted Record' },
  {
    value: 'deleted-record-with-filter-automation',
    label: 'Deleted Record with Filter',
  },
];

interface HourlyScheduleConfig {
  frequency: 'hourly';
  shouldRunOnWeekends: boolean;
}
interface DailyScheduleConfig {
  frequency: 'daily';
  shouldRunOnWeekends: boolean;
  timeOfDay: string;
}
interface WeeklycheduleConfig {
  frequency: 'weekly';
  dayOfTheWeek:
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';
  timeOfDay: string;
}
interface MonthlyScheduleConfig {
  frequency: 'monthly';
  dayOfMonth: number;
  timeOfDay: string;
}
export type ScheduleConfig =
  | HourlyScheduleConfig
  | DailyScheduleConfig
  | WeeklycheduleConfig
  | MonthlyScheduleConfig;

export const defaultScheduleOptionsForFrequency = (
  frequency: 'hourly' | 'weekly' | 'daily' | 'monthly'
): ScheduleConfig => {
  switch (frequency) {
    case 'hourly':
      return {
        frequency: 'hourly',
        shouldRunOnWeekends: true,
      };
    case 'daily':
      return {
        frequency: 'daily',
        shouldRunOnWeekends: true,
        timeOfDay: '9am',
      };
    case 'weekly':
      return {
        frequency: 'weekly',
        dayOfTheWeek: 'monday',
        timeOfDay: '9am',
      };
    case 'monthly':
      return {
        frequency: 'monthly',
        dayOfMonth: 1,
        timeOfDay: '9am',
      };

    default:
      break;
  }
  return {
    frequency: 'hourly',
    shouldRunOnWeekends: true,
  };
};

export interface BaseRecordAutomation {
  readonly companyId: string;
  readonly contentType: string;
  readonly 'companyId#contentType': string;
  readonly automationId: string;
  readonly 'type#enabledDT'?: string;
  readonly name: string;
  readonly description: string;
  readonly enabledDT?: string;
  readonly type: RecordAutomationTriggerType;
  readonly createdDT: string;
  readonly updatedDT: string;
  readonly lastModifiedBy?: string;
  readonly lastModifiedDT?: string;
  readonly actions: RecordAutomationAction[];
  readonly triggerConfig: {
    filters?: FilterConditionGroup;
    tableViewId?: string;
    scheduleOptions?: ScheduleConfig;
  };
}

export interface NewRecordAutomation extends BaseRecordAutomation {
  type: 'new-record-automation';
  triggerConfig: {};
}
export interface NewRecordWithFilterAutomation extends BaseRecordAutomation {
  type: 'new-record-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
  };
}
export interface NewRecordInViewAutomation extends BaseRecordAutomation {
  type: 'new-record-in-view-automation';
  triggerConfig: {
    tableViewId: string;
  };
}
export interface UpdatedRecordAutomation extends BaseRecordAutomation {
  type: 'updated-record-automation';
  triggerConfig: {};
}
export interface UpdatedRecordWithFilterAutomation
  extends BaseRecordAutomation {
  type: 'updated-record-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
  };
}
export interface UpdatedRecordInViewAutomation extends BaseRecordAutomation {
  type: 'updated-record-in-view-automation';
  triggerConfig: {
    tableViewId: string;
  };
}
export interface DeletedRecordAutomation extends BaseRecordAutomation {
  type: 'deleted-record-automation';
  triggerConfig: {};
}
export interface DeletedRecordWithFilterAutomation
  extends BaseRecordAutomation {
  type: 'deleted-record-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
  };
}

export const isHourlyScheduledFilterAutomation = (
  automation: unknown
): automation is HourlyScheduledFilterAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-with-filter-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'hourly'
  );
};

export interface HourlyScheduledFilterAutomation extends BaseRecordAutomation {
  type: 'scheduled-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
    scheduleOptions: {
      frequency: 'hourly';
      shouldRunOnWeekends: boolean;
    };
  };
}
export const isHourlyScheduledViewAutomation = (
  automation: unknown
): automation is HourlyScheduledViewAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-in-view-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'hourly'
  );
};
export interface HourlyScheduledViewAutomation extends BaseRecordAutomation {
  type: 'scheduled-in-view-automation';
  triggerConfig: {
    tableViewId: string;
    scheduleOptions: {
      frequency: 'hourly';
      shouldRunOnWeekends: boolean;
    };
  };
}
export const isDailyScheduledFilterAutomation = (
  automation: unknown
): automation is DailyScheduledFilterAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-with-filter-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'daily'
  );
};
export interface DailyScheduledFilterAutomation extends BaseRecordAutomation {
  type: 'scheduled-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
    scheduleOptions: {
      frequency: 'daily';
      shouldRunOnWeekends: boolean;
      timeOfDay: string;
    };
  };
}
export const isDailyScheduledViewAutomation = (
  automation: unknown
): automation is DailyScheduledViewAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-in-view-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'daily'
  );
};
export interface DailyScheduledViewAutomation extends BaseRecordAutomation {
  type: 'scheduled-in-view-automation';
  triggerConfig: {
    tableViewId: string;
    scheduleOptions: {
      frequency: 'daily';
      shouldRunOnWeekends: boolean;
      timeOfDay: string;
    };
  };
}
export const isMonthlyScheduledFilterAutomation = (
  automation: unknown
): automation is MonthlyScheduledFilterAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-with-filter-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'monthly'
  );
};
export interface MonthlyScheduledFilterAutomation extends BaseRecordAutomation {
  type: 'scheduled-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
    scheduleOptions: {
      frequency: 'monthly';
      dayOfMonth: number;
      timeOfDay: string;
    };
  };
}
export const isMonthlyScheduledViewAutomation = (
  automation: unknown
): automation is MonthlyScheduledViewAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-in-view-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'monthly'
  );
};
export interface MonthlyScheduledViewAutomation extends BaseRecordAutomation {
  type: 'scheduled-in-view-automation';
  triggerConfig: {
    tableViewId: string;
    scheduleOptions: {
      frequency: 'monthly';
      dayOfMonth: number;
      timeOfDay: string;
    };
  };
}
export const isWeeklyScheduledFilterAutomation = (
  automation: unknown
): automation is WeeklyScheduledFilterAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-with-filter-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'weekly'
  );
};
export interface WeeklyScheduledFilterAutomation extends BaseRecordAutomation {
  type: 'scheduled-with-filter-automation';
  triggerConfig: {
    filters: FilterConditionGroup;
    scheduleOptions: {
      frequency: 'weekly';
      dayOfTheWeek:
        | 'monday'
        | 'tuesday'
        | 'wednesday'
        | 'thursday'
        | 'friday'
        | 'saturday'
        | 'sunday';
      timeOfDay: string;
    };
  };
}
export const isWeeklyScheduledViewAutomation = (
  automation: unknown
): automation is WeeklyScheduledViewAutomation => {
  return (
    _get(automation, ['type']) === 'scheduled-in-view-automation' &&
    _get(automation, ['triggerConfig', 'scheduleOptions', 'frequency']) ===
      'weekly'
  );
};
export interface WeeklyScheduledViewAutomation extends BaseRecordAutomation {
  type: 'scheduled-in-view-automation';
  triggerConfig: {
    tableViewId: string;
    scheduleOptions: {
      frequency: 'weekly';
      dayOfTheWeek:
        | 'monday'
        | 'tuesday'
        | 'wednesday'
        | 'thursday'
        | 'friday'
        | 'saturday'
        | 'sunday';
      timeOfDay: string;
    };
  };
}
export const isScheduledWithFilterAutomation = (
  automation: unknown
): automation is ScheduledWithFilterAutomation => {
  return (
    isHourlyScheduledFilterAutomation(automation) ||
    isWeeklyScheduledFilterAutomation(automation) ||
    isDailyScheduledFilterAutomation(automation) ||
    isMonthlyScheduledFilterAutomation(automation)
  );
};
export type ScheduledWithFilterAutomation =
  | HourlyScheduledFilterAutomation
  | WeeklyScheduledFilterAutomation
  | MonthlyScheduledFilterAutomation
  | DailyScheduledFilterAutomation;

export const isScheduledViewAutomation = (
  automation: unknown
): automation is ScheduledWithViewAutomation => {
  return (
    isHourlyScheduledViewAutomation(automation) ||
    isWeeklyScheduledViewAutomation(automation) ||
    isDailyScheduledViewAutomation(automation) ||
    isMonthlyScheduledViewAutomation(automation)
  );
};
export type ScheduledWithViewAutomation =
  | HourlyScheduledViewAutomation
  | WeeklyScheduledViewAutomation
  | MonthlyScheduledViewAutomation
  | DailyScheduledViewAutomation;

export type RecordAutomation =
  | NewRecordAutomation
  | NewRecordWithFilterAutomation
  | NewRecordInViewAutomation
  | UpdatedRecordAutomation
  | UpdatedRecordWithFilterAutomation
  | UpdatedRecordInViewAutomation
  | DeletedRecordAutomation
  | DeletedRecordWithFilterAutomation
  | ScheduledWithFilterAutomation
  | ScheduledWithViewAutomation;
