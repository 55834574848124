import { get as _get } from 'lodash';

import {
  FilterCondition,
  Field,
  EnrichedDataRecord,
  TEXT_FILTER_EXPRESSION_OPTIONS,
} from '../../../types';
import { FieldTextValue, isString } from '../../../types/fieldValues';
import { FieldFilterFunction } from '../filteringCore';

export const getTextFilteringFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
): FieldFilterFunction => {
  const compareTo = condition.compareTo;
  const expression = condition.expression as TEXT_FILTER_EXPRESSION_OPTIONS;
  switch (expression) {
    case 'is':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;

        return (
          value !== undefined && value.compareValue === condition.compareTo
        );
      };

    case 'contains':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;

        return (
          value !== undefined &&
          value.compareValue !== undefined &&
          value.compareValue.includes(compareTo)
        );
      };

    case 'does not contain':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;
        if (value === undefined || value.compareValue === undefined) {
          return true;
        }
        return !value.compareValue.includes(compareTo);
      };

    case 'is not':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;
        if (value === undefined || value.compareValue === undefined) {
          return true;
        }
        return value.compareValue !== condition.compareTo;
      };

    case 'is empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;

        return (
          value === undefined ||
          value.compareValue === undefined ||
          value.compareValue === ''
        );
      };

    case 'is not empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldTextValue;

        return (
          value !== undefined &&
          value.compareValue !== undefined &&
          value.compareValue !== ''
        );
      };

    default:
      throw new Error(`Unhandled text filter for expression ${expression} }`);
  }
};
