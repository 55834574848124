import { FieldType, FieldConfig } from '../types';

export const getFieldConfigDefault = (fieldType: FieldType): FieldConfig => {
  switch (fieldType) {
    case 'text':
    case 'color':
    case 'email':
    case 'checkbox':
    case 'number':
    case 'phone':
    case 'url':
      return {
        defaultValue: null,
      };
    case 'date':
    case 'datetime':
    case 'slider':
      return {
        defaultValue: null,
        min: null,
        max: null,
      };
    case 'attachment':
      return {
        fileType: null,
        minSizeKB: null,
        maxSizeKB: null,
        maxImageHeight: null,
        minImageHeight: null,
        maxImageWidth: null,
        minImageWidth: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxTotalFileSizeKB: null,
      };
    case 'select':
    case 'multi-select':
    case 'select-user':
    case 'multi-select-user':
      return {
        defaultValue: null,
        options: [],
      };
    default:
      break;
  }
  return {
    defaultValue: null,
  };
};
