import { reduce as _reduce, get as _get } from 'lodash';
import {
  DataRecord,
  EnrichedDataRecord,
  Field,
  FieldSelectOptions,
} from '../types';
import { FieldValue, PersistedFieldValue } from '../types/fieldValues';
import { castFieldValue } from './castFieldValues';

export const enrichRecord = (
  record: DataRecord,
  fields: Record<string, Field>,
  userOptions?: FieldSelectOptions,
  getLinkedRecord?: (
    companyId: string,
    contentTypeSlug: string,
    recordId: string
  ) => Partial<DataRecord>
): EnrichedDataRecord => {
  const enrichedFields: Record<string, FieldValue> = _reduce<
    Record<string, PersistedFieldValue | undefined>,
    Record<string, FieldValue>
  >(
    record.fields,
    (prev, curr, key, list) => {
      const field = _get(fields, key);
      if (field === undefined) {
        return prev;
      }
      const fieldValue = castFieldValue(
        curr,
        field,
        userOptions,
        getLinkedRecord
      );
      return {
        ...prev,
        [key]: fieldValue,
      };
    },
    {}
  );
  const enrichedRecord: EnrichedDataRecord = {
    ...record,
    enrichedFields,
  };

  return enrichedRecord;
};

export const enrichRecords = (
  records: DataRecord[],
  fields: Record<string, Field>,
  userOptions?: FieldSelectOptions
): EnrichedDataRecord[] => {
  return records.map((record) => enrichRecord(record, fields, userOptions));
};
