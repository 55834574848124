import {
  Field,
  FilterCondition,
  isFieldText,
  isFieldFormula,
  isFieldLongText,
  isFieldEmail,
  isFieldURL,
  isFieldPhone,
  isFieldColor,
  isFieldNumber,
  isFieldSelect,
  isFieldSelectUser,
  isFieldMultiSelect,
  isFieldMultiSelectUser,
  isFieldCheckbox,
  isFieldDate,
  isFieldDatetime,
} from '../../types';

export const getFieldFilterConditionDefault = (
  field: Field
): FilterCondition => {
  const { fieldId } = field;
  if (
    isFieldText(field) ||
    isFieldFormula(field) ||
    isFieldLongText(field) ||
    isFieldEmail(field) ||
    isFieldURL(field) ||
    isFieldPhone(field) ||
    isFieldColor(field)
  ) {
    return {
      fieldId: fieldId,
      expression: 'contains',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (isFieldNumber(field)) {
    return {
      fieldId: fieldId,
      expression: '=',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (isFieldSelect(field) || isFieldSelectUser(field)) {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (isFieldMultiSelect(field) || isFieldMultiSelectUser(field)) {
    return {
      fieldId: fieldId,
      expression: 'is exactly',
      compareTo: [],
      option: undefined,
    };
  }
  if (isFieldCheckbox(field)) {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: false,
    };
  }
  if (isFieldDate(field) || isFieldDatetime(field)) {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: undefined,
      option: 'exact date...',
    };
  }
  return {
    fieldId: fieldId,
    expression: 'is not empty',
    compareTo: undefined,
    option: undefined,
  };
};
