import { User, FieldSelectOptions } from '../types';

export const castUsersToFieldSelectOptions = (
  users: User[]
): FieldSelectOptions => {
  return users.map((user) => ({
    value: user.userId,
    label: user.name ?? user.email,
  }));
};
