import { Result } from '@uppy/transloadit';

export interface AttachmentMeta {}
export const isVideoAttachment = (value: unknown): value is VideoAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ['type', 'original'];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === 'video-attachment'
  ) {
    return true;
  }
  return false;
};
export interface VideoAttachment {
  type: 'video-attachment';
  original: Result;
  video_mp4_1080p: Result;
  thumbnail_150x150: Result[];
  video_cover: Result[];
  audio_aac: Result | undefined;
}
export const isImageAttachment = (value: unknown): value is ImageAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ['type', 'original'];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === 'image-attachment'
  ) {
    return true;
  }
  return false;
};
export interface ImageAttachment {
  type: 'image-attachment';
  original: Result;
  image_landscape: Result;
  thumbnail_150x150: Result;
  image_1920: Result;
}
export const isAudioAttachment = (value: unknown): value is AudioAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ['type', 'original'];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === 'audio-attachment'
  ) {
    return true;
  }
  return false;
};
export interface AudioAttachment {
  type: 'audio-attachment';
  original: Result;
  audio_aac: Result;
}
export const isFileAttachment = (value: unknown): value is FileAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ['type', 'original'];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === 'file-attachment'
  ) {
    return true;
  }
  return false;
};
export interface FileAttachment {
  type: 'file-attachment';
  original: Result;
  image_landscape: Result | undefined;
  thumbnail_150x150: Result | undefined;
  image_1920: Result | undefined;
}
export type Attachment =
  | FileAttachment
  | AudioAttachment
  | ImageAttachment
  | VideoAttachment;

export const isAttachment = (value: unknown): value is Attachment => {
  if (value === undefined) {
    return false;
  }
  if (Array.isArray(value)) {
    return false;
  }
  try {
    return (
      isFileAttachment(value) ||
      isAudioAttachment(value) ||
      isImageAttachment(value) ||
      isVideoAttachment(value)
    );
  } catch (error) {}
  return false;
};
