import { get as _get } from 'lodash';

import {
  FilterCondition,
  Field,
  EnrichedDataRecord,
  SELECT_FILTER_EXPRESSION_OPTIONS,
} from '../../../types';
import {
  FieldSelectValue,
  isString,
  isStringArray,
} from '../../../types/fieldValues';
import { FieldFilterFunction } from '../filteringCore';

export const getSelectFilteringFunction = (
  condition: FilterCondition,
  _fields: Record<string, Field>
): FieldFilterFunction => {
  const compareTo = condition.compareTo;
  const expression = condition.expression as SELECT_FILTER_EXPRESSION_OPTIONS;
  switch (expression) {
    case 'is':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;
        return value !== undefined && value.compareValue === compareTo;
      };

    case 'is any of':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;
        if (value === undefined || value.compareValue === undefined) {
          return false;
        }
        return compareTo.some((val) => value.compareValue === val);
      };

    case 'is none of':
      return (record: EnrichedDataRecord) => {
        if (!isStringArray(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;
        if (value === undefined || value.compareValue === undefined) {
          return true;
        }
        return !compareTo.some((val) => value.compareValue === val);
      };

    case 'is not':
      return (record: EnrichedDataRecord) => {
        if (!isString(compareTo)) {
          return true;
        }
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;
        if (value === undefined) {
          return true;
        }
        return value.compareValue !== condition.compareTo;
      };

    case 'is empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;

        return (
          value === undefined ||
          value.compareValue === undefined ||
          value.compareValue === ''
        );
      };

    case 'is not empty':
      return (record: EnrichedDataRecord) => {
        const value = _get(record, [
          'enrichedFields',
          condition.fieldId,
        ]) as FieldSelectValue;

        return (
          value !== undefined &&
          value.compareValue !== undefined &&
          value.compareValue !== ''
        );
      };

    default:
      throw new Error(`Unhandled text filter for expression ${expression} }`);
  }
};
